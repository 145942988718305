
import { Options, Vue } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import CreateUpdate from "@/app/ui/components/data-wrapper/create-update.vue";
import OverlayPanel from "primevue/overlaypanel";
import {
  convertDecimalWithComma,
  formatDate,
  dateToDateString
} from "@/app/infrastructures/misc/Utils";
import { StiDestController } from "@/app/ui/controllers/StiDestController";
import { AccountController } from "@/app/ui/controllers/AccountController";
import {
  StiDestManifestData,
  StiDestSttResult,
  SttPiece
} from "@/domain/entities/StiDest";

@Options({
  components: {
    DetailLayout,
    CreateUpdate,
    OverlayPanel
  }
})
export default class Detail extends Vue {
  refs: any = "";
  mounted() {
    this.refs = this.$refs;
    this.getDetail();
  }

  get id(): number {
    return Number(this.$route.params.id);
  }

  get title() {
    return `STI-DEST Manifest - ${this.id}`;
  }

  // route navigation
  goBack() {
    const path =
      (this.$route.meta as any)["parent-path"] || this.$route.matched[0].path;
    this.$router.push(path);
  }

  getDetail() {
    StiDestController.getDetailStiDest({id: Number(this.$route.params.id), version: "v1"});
  }

  get detailData() {
    return StiDestController.stiDestDetail;
  }

  get convertCreatedAt() {
    return formatDate(this.detailData.stiDestDate);
  }

  get isLoading() {
    return StiDestController.isLoading;
  }
  get isError() {
    return StiDestController.isError;
  }
  get errorCause() {
    return StiDestController.errorCause;
  }

  //table
  get columns() {
    return [
      {
        name: "No.",
        styleHead: "w-10 text-left whitespace-nowrap",
        styleCustom: "align-top",
        render: (item: any, index: number) => {
          return `<div class="text-black-lp-300">${index + 1}</div>`;
        }
      },
      {
        name: `No. Bag`,
        styleHead: "w-48 text-left whitespace-nowrap",
        styleCustom: "align-top",
        render: (item: any) => {
          return `<div class="text-black-lp-300 whitespace-nowrap">${item.sttBagVendorNo || item.sttBagNo}</div>`;
        }
      },
      {
        name: `No. STT`,
        styleHead: "w-48 text-left whitespace-nowrap",
        styleCustom: "align-top",
        render: (item: any) => {
          return `<div class="text-black-lp-300 whitespace-nowrap">${item.sttNo}</div>`;
        }
      },
      {
        name: "Product Name",
        styleHead: "w-48 text-left whitespace-nowrap",
        styleCustom: "align-top",
        render: (item: any) => {
          return `<div class="text-black-lp-300 rounded px-2 py-0 bg-gray-lp-400 whitespace-nowrap">
                      ${item.sttProductType}
                  </div>`;
        }
      },
      {
        name: "Commodity Name",
        styleHead: "w-48 text-left whitespace-nowrap",
        styleCustom: "align-top",
        render: (item: any) => {
          return `<div class="text-black-lp-300">${item.sttCommodityName}</div>`;
        }
      },
      {
        name: "Total Koli",
        styleHead: "w-24 text-left whitespace-nowrap",
        styleCustom: "align-top",
        render: (item: any) => {
          return `<div class="text-black-lp-300">${item.sttTotalPiece}</div>`;
        }
      },
      {
        name: "Berat Kotor",
        styleHead: "w-24 text-left whitespace-nowrap",
        styleCustom: "align-top",
        render: (item: any) => {
          return `<div class="text-black-lp-300">${convertDecimalWithComma(
            item.sttGrossWeight,
            2
          )} Kg</div>`;
        }
      },
      {
        name: "Berat Dimensi",
        styleHead: "w-32 text-left whitespace-nowrap",
        styleCustom: "align-top",
        render: (item: any) => {
          return `<div class="text-black-lp-300">${convertDecimalWithComma(
            item.sttVolumeWeight,
            2
          )} Kg</div>`;
        }
      },
      {
        name: "Origin",
        styleHead: "w-24 text-left whitespace-nowrap",
        styleCustom: "align-top",
        render: (item: any) => {
          return `<div class="text-black-lp-300 rounded px-2 py-0 bg-gray-lp-400 whitespace-nowrap">
                      ${item.origin}
                  </div>`;
        }
      },
      {
        name: "Dest.",
        styleHead: "w-24 text-left whitespace-nowrap",
        styleCustom: "align-top",
        render: (item: any) => {
          return `<div class="text-black-lp-300 rounded px-2 py-0 bg-gray-lp-400 whitespace-nowrap">
                      ${item.destination}
                  </div>`;
        }
      }
    ];
  }

  async print(type: string) {
    const data: any = [
      {
        name: "Kota Kedatangan",
        value: `${this.detailData.destinationCityCode} - ${this.detailData.destinationConsolidator}`
      },
      {
        name: "Nama Consolidator",
        value: AccountController.accountData.name
      },
      {
        name: "Tanggal STI-DEST",
        value: dateToDateString(this.detailData.stiDestDate)
      },
      {
        name: "Total STT",
        value: String(this.detailData.totalStt)
      },
      {
        name: "Total Koli",
        value: String(this.detailData.totalPiece)
      },
      {
        name: "Total Berat Kotor",
        value: `${convertDecimalWithComma(
          this.detailData.totalGrossWeight,
          2
        )} Kg`
      },
      {
        name: "Total Berat Dimensi",
        value: `${convertDecimalWithComma(
          this.detailData.totalVolumeWeight,
          2
        )} Kg`
      }
    ];

    const dataPrintPdfManifest = new StiDestManifestData(
      this.detailData.stiDestId,
      this.detailData.consolidatorName,
      this.detailData.destinationConsolidator,
      this.detailData.destinationCityCode,
      this.detailData.stiDestCargoNo,
      this.detailData.stiDestCustomVehicleNo,
      this.detailData.stiDestCargoType,
      this.detailData.totalStt,
      this.detailData.totalPiece,
      this.detailData.totalGrossWeight,
      this.detailData.totalVolumeWeight,
      this.detailData.stiDestDate,
      this.detailData.stt.map(
        (item: any) =>
          new StiDestSttResult({
            sttId: item.sttId,
            sttNo: item.sttNo,
            sttProductType: item.sttProductType,
            sttTotalPiece: item.sttTotalPiece,
            sttDestinationCityId: item.sttDestinationCityId,
            sttDestinationCityName: item.sttDestinationCityName,
            sttWoodPacking: item.sttWoodPacking,
            sttCommodityCode: item.sttCommodityCode,
            sttCommodityName: item.sttCommodityName,
            sttGrossWeight: item.sttGrossWeight,
            sttVolumeWeight: item.sttVolumeWeight,
            sttChargeableWeight: item.sttChargeableWeight,
            sttCodAmount: item.sttCodAmount,
            sttBagNo: item.sttBagVendorNo || item.sttBagNo,
            sttPiece: item.sttPiece.map(
              (e: any) =>
                new SttPiece({
                  sttPieceId: e.sttPieceId,
                  sttPieceGrossWeight: e.sttPieceGrossWeight,
                  sttPieceVolumeWeight: e.sttPieceVolumeWeight,
                  sttPieceNo: e.sttPieceNo
                })
            ),
            origin: item.origin,
            destination: item.destination
          })
      )
    );

    const print: any = await import(
      "@/app/ui/views/incoming-process/sti-dest/modules/print.vue"
    );
    const logo = await import(
      `@/app/ui/assets/images/logo-${this.roleAccount}.png`
    );
    const printType: any = {
      summary: () =>
        print.default.methods.printSummary(
          data,
          this.detailData.stiDestId,
          logo.default
        ),
      manifest: () =>
        print.default.methods.printManifest(dataPrintPdfManifest, logo.default)
    };

    printType[type]();
  }

  get roleAccount() {
    return (
      (this.dataProfile.account_type === "partner"
        ? this.dataProfile.account_type_detail.type
        : this.dataProfile.account_type) || "internal"
    );
  }

  get dataProfile() {
    return AccountController.accountData;
  }

  convertDecimalWithComma(value: any, round: number) {
    return convertDecimalWithComma(value, round);
  }
}
