import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { Pagination } from "@/domain/entities/Pagination";
import { container } from "tsyringe";
import { StiDestPresenter } from "../presenters/StiDestPresenter";
import {
  StiDestEntities,
  StiDestSttList,
  StiDestManifestData,
  StiDestDetail,
  RequestListSTIDestTemporary,
  StiDestSummary,
  PriorityDelSummary,
  SummaryNeedToSTIDestDel,
  ListNeedToSTIDestDel,
  RequestListNeedToSTIDestDel
} from "@/domain/entities/StiDest";
import {
  GenerateStiDestRequest,
  StiDestListRequest,
  StiDestReverseScanRequest
} from "@/data/payload/api/StiDestApiRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { MainAppController } from "./MainAppController";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { playNotification } from "@/app/infrastructures/misc/UtilsAudio";
import { ErrorMessageEntities } from "@/domain/entities/MainApp";
import { FAILED_ERROR } from "@/app/infrastructures/misc/Constants";

export interface StiDestState {
  isLoading: boolean;
  isError: boolean;
  errorCause: string;
  stiDestList: StiDestEntities;
}

@Module({ namespaced: true, dynamic: true, store, name: "incoming-sti-dest" })
class StiDestStore extends VuexModule implements StiDestState {
  public isLoading = false;
  public isStiDestSummaryLoading = false;
  public isPriorSummaryLoading = false;
  public isFailed = false;
  public isError = false;
  public errorCause = "";
  public filter = {
    startDate: "",
    endDate: "",
    search: ""
  };

  public stiDestList = new StiDestEntities(new Pagination(1, 10), []);
  public stiDestDetail = new StiDestDetail();
  public stiDestSummary = new StiDestSummary();
  public priorityDelSummary = new PriorityDelSummary();
  public isLoadingPrint = false;
  public isErrorPrint = false;
  public isOpenSuccess = false;
  public errorPrintCause = "";
  public StiDestIdToGeneratePdf = 0;
  public StiDestManifestData = new StiDestManifestData(
    0,
    "",
    "",
    "",
    "",
    "",
    "",
    0,
    0,
    0,
    0,
    "",
    []
  );
  public sttFailed: Array<string> = [];

  @Action
  getStiDestList(params: StiDestListRequest) {
    this.setIsLoading(true);
    const presenter = container.resolve(StiDestPresenter);
    return presenter
      .getListStiDest(params)
      .then((res: StiDestEntities) => {
        this.setStiDest(res);
        this.setErrorCause("");
      })
      .catch((err: any) => {
        this.setErrorCause(err.response ? "server" : "internet");
        this.setError(true);
        this.setStiDest(new StiDestEntities(new Pagination(0, 0), []));
      })
      .finally(() => this.setIsLoading(false));
  }

  @Action
  async getDetailStiDest(params:{id: number, version: string}) {
    this.setIsLoading(true);
    const presenter = container.resolve(StiDestPresenter);
    return presenter
      .getDetailStiDest(params.id, params.version)
      .then((res: StiDestDetail) => {
        this.setDetailStiDest(res);
        this.setError(false);
        this.setErrorCause("");
        return true
      })
      .catch((err: any) => {
        this.setErrorCause(err.response ? "server" : "internet");
        this.setError(true);
        this.setDetailStiDest(new StiDestDetail());
        return false
      })
      .finally(() => this.setIsLoading(false));
  }

  @Action
  public async getStiDestSttList(params: { bagOrStt: string }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(StiDestPresenter);
    return presenter
      .getStiDestSttList(params.bagOrStt)
      .then((res: StiDestSttList) => res.data)
      .catch((err: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Tambah No. STT Gagal!", () =>
            this.getStiDestSttList(params)
          )
        );
        return err.response.data;
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public async getStiDestSttListLilo(params: { bagNo: string }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(StiDestPresenter);
    return presenter
      .getStiDestSttListLilo(params.bagNo)
      .then((res: StiDestSttList) => res.data)
      .catch((err: any) => {
        playNotification("error");
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Tambah No. STT Gagal!", () =>
            this.getStiDestSttListLilo(params)
          )
        );
        return err.response.data;
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public fetchStiDestList(version = "v1") {
    this.getStiDestList(
      new StiDestListRequest({
        version,
        page: this.stiDestList.pagination.page,
        limit: this.stiDestList.pagination.limit,
        search: encodeURIComponent(this.filter.search),
        startDate: new Date(this.filter.startDate).toLocaleDateString("fr-CA"),
        endDate: new Date(this.filter.endDate).toLocaleDateString("fr-CA")
      })
    );
  }

  @Action
  public fetchStiDestSummary() {
    this.setIsSummaryLoading(true);
    const presenter = container.resolve(StiDestPresenter);
    return presenter
      .getStiDestSummary()
      .then((res: StiDestSummary) => {
        this.setStiDestSummary(res);
        this.setIsSummaryLoading(false);
        return true;
      })
      .catch((err: any) => {
        this.setStiDestSummary(new StiDestSummary());
        this.setIsSummaryLoading(false);
        return false;
      })
      .finally(() => this.setIsSummaryLoading(false));
  }

  @Action
  public fetchGetPriorityDelSummary() {
    this.setIsPriorSummaryLoading(true);
    const presenter = container.resolve(StiDestPresenter);
    return presenter
      .getPriorityDelSummary()
      .then((res: PriorityDelSummary) => {
        this.setPriorityDelSummary(res);
        this.setIsPriorSummaryLoading(false);
        return true;
      })
      .catch((err: any) => {
        this.setPriorityDelSummary(new PriorityDelSummary());
        this.setIsPriorSummaryLoading(false);
        return false;
      })
      .finally(() => this.setIsPriorSummaryLoading(false));
  }

  @Mutation
  setFirstPage() {
    this.stiDestList.pagination.page = 1;
  }

  @Action
  public getStiDestManifestDetail(params: { id: number }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(StiDestPresenter);
    return presenter
      .getStiDestManifestDetail(params.id)
      .then((res: StiDestManifestData) => {
        this.setStiDestManifestData(res);

        return true;
      })
      .catch((err: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Print Gagal !", () => {
            MainAppController.closeErrorMessage();
            this.getStiDestManifestDetail({
              id: params.id
            });
          })
        );
        return false;
      })
      .finally(() => MainAppController.closeLoading());
  }

  @Action
  public onGenerateStiDest(params: { sttNumbers: any }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(StiDestPresenter);
    return presenter
      .generateStiDest(new GenerateStiDestRequest(params.sttNumbers))
      .then((res: ResponsePayload) => {
        this.setStiDestIdToGeneratePdf(res.data.stiDescId);
        return res;
      })
      .catch((err: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Pembaruan Status Gagal !", () => {
            MainAppController.closeErrorMessage();
            this.onGenerateStiDest({
              sttNumbers: params.sttNumbers
            });
          })
        );
        this.setOpenSuccess(false);
        return new ResponsePayload();
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public downloadStiDest(id: number) {
    MainAppController.showLoading();
    const presenter = container.resolve(StiDestPresenter);
    presenter
      .downloadStiDest(id)
      .catch((err: any) =>
        MainAppController.showErrorMessage(parsingErrorResponse(err))
      )
      .finally(() => MainAppController.closeLoading());
  }

  stiDestSummaryNeedToDel = new SummaryNeedToSTIDestDel();
  listStiDestNeedToDel = new ListNeedToSTIDestDel();
  

  @Action
  public getSummarySTIDestNeedToDel() {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(StiDestPresenter);
    return presenter
    .getSummarySTIDestNeedToDel()
    .then((res: SummaryNeedToSTIDestDel) => {
      this.setSTIDestSummary(res);
    }).catch(err => {
      MainAppController.showErrorMessage(
        parsingErrorResponse(
          err, 
          "Gagal Mengambil Data Summary!", 
          () => this.getSummarySTIDestNeedToDel()
        )
      );
    }).finally(()=>{
      MainAppController.closeLoading();
    })
  }

  isLoadingListSTIDestNeedToDel = false;

  @Action
  public getListSTIDestNeedToDel(params: RequestListNeedToSTIDestDel) {
    this.setLoadingListSTIDestNeedToDel(true);
    const presenter = container.resolve(StiDestPresenter);
    return presenter
    .getListSTIDestNeedToDel(params)
    .then((res: ListNeedToSTIDestDel) => {
      this.setListSTIDestNeedToDel(res);
      this.setErrorCauseNeedToSTIDest("");
    }).catch(err => {
      this.setErrorCauseNeedToSTIDest(err.response ? "server" : "internet");
      this.setListSTIDestNeedToDel(new ListNeedToSTIDestDel());
    }).finally(()=>{
    this.setLoadingListSTIDestNeedToDel(false);
    })
  }

  @Action
  public getDownloadExcelSTIDestNeedToDel() {
    MainAppController.showLoading();
    const presenter = container.resolve(StiDestPresenter);
    presenter
      .getDownloadExcelSTIDestNeedToDel()
      .catch((err: any) => {
        const message = err.response.data.message;
        MainAppController.showErrorMessage(
          typeof message === "string"
            ? new ErrorMessageEntities({
                message: message,
                title: "Server Error",
                type: FAILED_ERROR
              })
            : parsingErrorResponse(
                err,
                "Gagal mengunduh data daftar harus STI DEST & DEL!"
              )
        );
      })
      .finally(() => MainAppController.closeLoading());
  }

  @Action
  public downloadExcelStiDestV2(params: {id: number, version: string}) {
    MainAppController.showLoading();
    const presenter = container.resolve(StiDestPresenter);
    presenter
      .downloadStiDest(params.id, params.version)
      .catch((err: any) =>
        MainAppController.showErrorMessage(parsingErrorResponse(err))
      )
      .finally(() => MainAppController.closeLoading());
  }
  
  @Action
  public stiDestReverseScan(payload: StiDestReverseScanRequest) {
    const presenter = container.resolve(StiDestPresenter);
    return presenter.stiDestReverseScan(payload)
  }

  @Action
  public stiDestProcessReverseScan() {
    MainAppController.showLoading()
    const presenter = container.resolve(StiDestPresenter);
    return presenter.stiDestProcessReverseScan()
    .then((res: ResponsePayload) => {
      this.setStiDestIdToGeneratePdf(res.data.stiDescId);
      this.setOpenSuccess(true);
      return res;
    })
    .catch((err: any) => {
      MainAppController.showErrorMessage(
        parsingErrorResponse(err, "Pembaruan Status Gagal !")
      );
      this.setOpenSuccess(false);
      this.setFailed(true)
      return new ResponsePayload();
    })
    .finally(() => {
      MainAppController.closeLoading();
    });
  }

  @Action
  public getListStiDestTemporary(params: RequestListSTIDestTemporary) {
    const presenter = container.resolve(StiDestPresenter);
    return presenter.getListStiDestTemporary(params);
  }
  
  @Mutation
  setDetailStiDest(res: StiDestDetail) {
    this.stiDestDetail = res;
  }

  @Mutation
  setStiDestSummary(res: StiDestSummary) {
    this.stiDestSummary = res;
  }

  @Mutation
  setPriorityDelSummary(res: PriorityDelSummary) {
    this.priorityDelSummary = res;
  }

  @Mutation
  public setLoadingListSTIDestNeedToDel(val: boolean) {
    this.isLoadingListSTIDestNeedToDel = val;
  }

  @Mutation
  setIsLoadingPrint(val: boolean) {
    this.isLoadingPrint = val;
  }

  @Mutation
  setStiDestIdToGeneratePdf(val: number) {
    this.StiDestIdToGeneratePdf = val;
  }

  @Mutation
  setStiDestManifestData(val: StiDestManifestData) {
    this.StiDestManifestData = val;
  }

  @Mutation
  setErrorPrint(val: boolean) {
    this.isErrorPrint = val;
  }

  @Mutation
  setSTIDestSummary(val: SummaryNeedToSTIDestDel) {
    this.stiDestSummaryNeedToDel = val;
  }

  errCauseStiDestNeedToDel = "";

  @Mutation
  setErrorCauseNeedToSTIDest(val: string) {
    this.errCauseStiDestNeedToDel = val;
  }

  @Mutation
  public setFirstPageSTTDue(): void {
    this.listStiDestNeedToDel.pagination.page = 1;
  }

  @Mutation 
  setListSTIDestNeedToDel(val: ListNeedToSTIDestDel) {
    this.listStiDestNeedToDel = val;
  }

  @Mutation
  setErrorPrintCause(val: string) {
    this.errorPrintCause = val;
  }

  @Mutation
  private setStiDest(data: StiDestEntities) {
    this.stiDestList = data;
  }

  @Mutation
  public setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  @Mutation
  public setIsSummaryLoading(value: boolean) {
    this.isStiDestSummaryLoading = value;
  }

  @Mutation
  public setIsPriorSummaryLoading(value: boolean) {
    this.isPriorSummaryLoading = value;
  }

  @Mutation
  public setSearch(value: string) {
    this.filter.search = value;
  }

  @Mutation
  public setStartDate(value: any) {
    this.filter.startDate = value;
  }

  @Mutation
  public setEndDate(value: any) {
    this.filter.endDate = value;
  }

  @Mutation
  public setError(value: boolean) {
    this.isError = value;
  }

  @Mutation
  public setOpenSuccess(val: boolean) {
    this.isOpenSuccess = val;
  }

  @Mutation
  public setErrorCause(value: string) {
    this.errorCause = value;
  }

  @Mutation
  public setSttFailed(value: Array<string>) {
    this.sttFailed = value;
  }

  @Mutation
  public setFailed(value: boolean) {
    this.isFailed = value;
  }

  @Mutation
  public setFilter(value: any) {
    this.filter = value;
  }
}

export const StiDestController = getModule(StiDestStore);
